import { StoreAction } from "../../@core/constants";

const initialState = {
  allStaffList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.GET_ALL_STAFF_DATA:
      return { ...state, allStaffList: action.payload };

    default:
      return state;
  }
}
