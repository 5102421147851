import { StoreAction } from "../../@core/constants";

const initialState = { sizeList: [] }

export default function (state = initialState, action) {
    switch (action.type) {
        case StoreAction.GET_SIZE_DATA:
            return {
                ...state,
                sizeList: action.payload
            }
        default:
            return state
    }
}