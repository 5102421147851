import { StoreAction } from "../../@core/constants";

const initialState = {
  category_data: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.GET_CATEGORY_DATA:
      return {
        ...state,
        category_data: action.payload,
      }
    default:
      return state;
  }
}
