import { StoreAction } from "../../@core/constants";

const initialState = {
    leadStatusList: [],
}
export default function (state = initialState, action) {
    switch (action.type) {
        case StoreAction.GET_LEAD_STATUS:
            return {
              ...state,
              leadStatusList: action.payload,
            };
      
          default:
            return state;
    }
}