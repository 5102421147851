import { StoreAction } from "../../@core/constants";

const initialState = {
  store_data: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.GET_STORE_DATA:
      return {
        ...state,
        store_data: action.payload,
      };
    default:
      return state;
  }
}
