import { StoreAction } from "../../@core/constants";

const initialState = {
  paymentMethodList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.GET_PAYMENT_METHOD_DATA:
      return {
        ...state,
        paymentMethodList: action.payload,
      };

    default:
      return state;
  }
}
