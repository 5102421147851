// ** Reducers Imports
import navbar from "../navbar";
import layout from "../layout";
// import auth from '../authentication'
import organizationReducer from "./organizationReducer";
import storeReducer from "./storeReducer";
import categoryReducer from "./categoryReducer";
import staffReducer from "./staffReducer";
import subcategoryReducer from "./subcategoryReducer";
import colorReducer from "./colorReducer";
import sizeReducer from "./sizeReducer";
import leadReducer from "./leadReducer";
import wapmonkeyReducer from "./wapmonkeyReducer";
import paymentMethodReducer from "./paymentMethodReducer";
import leadStatusReducer from "./leadStatusReducer";
import leadSourceReducer from "./leadSourceReducer";
import websettingReducer from "./websettingReducer";
import { createAction } from "@reduxjs/toolkit";


export const revertAll = createAction('REVERT_ALL');

const rootReducer = {
  // auth,
  navbar,
  layout,
  organizationReducer,
  storeReducer,
  categoryReducer,
  staffReducer,
  subcategoryReducer,
  colorReducer,
  sizeReducer,
  leadReducer,
  wapmonkeyReducer,
  paymentMethodReducer,
  leadStatusReducer,
  leadSourceReducer,
  websettingReducer,
};

export default rootReducer;
