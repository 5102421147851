import { StoreAction } from "../../@core/constants";

const initialState = {
  subcategory_data: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.GET_SUBCATEGORY_DATA:
      return {
        ...state,
        subcategory_data: action.payload,
      };
    default:
      return state;
  }
}
