export const StoreAction = {
  // SET_REGISTRATION_DATA:"SET_REGISTRATION_DATA",
  // GET_REGISTRATION_DATA:"GET_REGISTRATION_DATA",

  GET_COLOR_DATA: "GET_COLOR_DATA",
  GET_PAYMENT_METHOD_DATA: "GET_PAYMENT_METHOD_DATA",
  GET_ALL_STAFF_DATA: 'GET_ALL_STAFF_DATA',
  GET_SIZE_DATA: 'GET_SIZE_DATA',
  GET_SUBCATEGORY_DATA: "GET_SUBCATEGORY_DATA",
  GET_CATEGORY_DATA: "GET_CATEGORY_DATA",
  GET_LEAD_STATUS: "GET_LEAD_STATUS",
  GET_LEAD_SOURCE: "GET_LEAD_SOURCE",
  GET_STORE_DATA: "GET_STORE_DATA",


  // GET_LOGIN_DATA: "GET_LOGIN_DATA",
  // SET_STORE_DATA: "SET_STORE_DATA",
  // GET_STORE_DATA: "GET_STORE_DATA",
  // SET_CATEGORY_DATA: "SET_CATEGORY_DATA",
  // GET_CATEGORY_DATA: "GET_CATEGORY_DATA",
  // UPDATE_CATEGORY_DATA: "UPDATE_CATEGORY_DATA",
  // SET_STAFF_DATA: "SET_STAFF_DATA",
  // GET_STAFF_DATA: "GET_STAFF_DATA",
  // UPDATE_STAFF_DATA: "UPDATE_STAFF_DATA",
  // SET_SUBCATEGORY_DATA: "SET_SUBCATEGORY_DATA",
  // GET_SUBCATEGORY_DATA: "GET_SUBCATEGORY_DATA",
  // UPDATE_SUBCATEGORY_DATA: "UPDATE_SUBCATEGORY_DATA",
  // SET_COLOR_DATA: "SET_COLOR_DATA",
  // GET_COLOR_DATA: "GET_COLOR_DATA",
  // UPDATE_COLOR_DATA: "UPDATE_COLOR_DATA",
  // SET_SIZE_DATA: "SET_SIZE_DATA",
  // GET_SIZE_DATA: "GET_SIZE_DATA",
  // UPDATE_SIZE_DATA: "UPDATE_SIZE_DATA",
  GET_ALL_LEAD_DATA: "GET_ALL_LEAD_DATA",
  GET_ALL_KANBAN_LEAD: "GET_ALL_KANBAN_LEAD",
  SET_PAYMENT_METHOD_DATA: "SET_PAYMENT_METHOD_DATA",
  GET_PAYMENT_METHOD_DATA: "GET_PAYMENT_METHOD_DATA",
  UPDATE_PAYMENT_METHOD_DATA: 'UPDATE_PAYMENT_METHOD_DATA',
  SET_WAP_MONKEY_WEB_SOCKET: "SET_WAP_MONKEY_WEB_SOCKET",
  SET_WAP_MONKEY_DEVICE_TYPE: "SET_WAP_MONKEY_DEVICE_TYPE",
  SET_WAP_MONKEY_DEVICE_DATA: "SET_WAP_MONKEY_DEVICE_DATA",
  SET_WAP_MONKEY_RECONNECT_DEVICE_DATA: "SET_WAP_MONKEY_RECONNECT_DEVICE_DATA",
  SET_WAP_MONKEY_USER_ID: "SET_WAP_MONKEY_USER_ID",
  GET_WEB_SETTING_DATA:"GET_WEB_SETTING_DATA"
};
