import { StoreAction } from "../../@core/constants";

const initialState = {
    leadSourceList: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case StoreAction.GET_LEAD_SOURCE:
            return {
              ...state,
              leadSourceList: action.payload,
            };
      
          default:
            return state;
    }
}